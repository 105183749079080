import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import LandingLinks from "./landing/links/Links";
import "./index.css";

function App() {

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<LandingLinks />} />
          <Route path="/*" element={<Navigate to="/" replace/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
