import React from 'react';
import logo from "../../assets/logo/cig3d.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';
import googleLogo from "../../assets/logo/google-reviews-logo.png"
import trustpilotLogo from "../../assets/logo/trustpilot.png"




function Links() {

  return (
    <>
        <Helmet>
            <title>Careisgold SpA: Links</title>
            <meta property="og:title" content="Careisgold SpA: Links" />
            <meta property="og:description" content="Seguici sui nostri social e lascia una recensione" />
            <meta property="og:url" content="https://www.careisgold.it/gold-tram" />
            <meta name="description" content="Seguici sui nostri social e lascia una recensione" />
            <meta name="keywords" content="careisgold, social, investimenti in oro, opportunità, collaboratori" />
            <meta name="author" content="Careisgold S.p.A" />
            <link rel="canonical" href="https://www.careisgold.it/links" />
            <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
            <script src="https://widget.trustmary.com/FFuhi9bjL"></script>
        </Helmet>
    
    <div className="centered-div bg-dark">
        <div className="container">
            <div className="row d-flex justify-content-center align-items-center my-5">
                <div className="col-5 col-md-4 col-lg-3 col-xl-2">
                    <img src={logo} alt="logo" className="img-fluid"/>
                </div>
                <div className="col-12 mt-4">
                    <div className="container">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-12 text-center">
                                <p className="text-animate-gold fs-4 fw-semibold">Seguici sui nostri canali social:</p>
                            </div>
                            <div className="col-12 text-center">
                                <a className="text-decoration-none" href="https://www.instagram.com/careisgoldspa/" target="_blank" rel="noopener noreferrer">
                                <button
                                    className="bg-instagram border-0 shadow text-center mx-auto d-flex justify-content-evenly text-light fw-semibold rounded mt-4 align-items-center"
                                    style={{"height":"3rem","width":"12rem"}}
                                >
                                    <FontAwesomeIcon
                                    className="fs-3 text-light me-2"
                                    icon={faInstagram}
                                    />
                                    @careisgoldspa
                                </button>                
                                </a>
                            </div>
                            <div className="col-12 text-center">
                                <a className="text-decoration-none" href="https://www.facebook.com/careisgoldspa/" target="_blank" rel="noopener noreferrer">
                                <button
                                    className="bg-facebook border-0 shadow mx-auto text-center d-flex justify-content-evenly text-light fw-semibold rounded mt-4 align-items-center"
                                    style={{"height":"3rem","width":"12rem"}}
                                >
                                    <FontAwesomeIcon
                                    className="fs-3 text-light me-2"
                                    icon={faFacebookF}
                                    />
                                    @careisgoldspa
                                </button>       
                                </a>
                            </div>
                            <div className="col-12 text-center">
                                <a className="text-decoration-none" href="https://www.linkedin.com/company/careisgold-spa" target="_blank" rel="noopener noreferrer">
                                <button
                                    className="bg-linkedin border-0 shadow mx-auto text-center d-flex justify-content-evenly text-light fw-semibold rounded mt-4 align-items-center"
                                    style={{"height":"3rem","width":"12rem"}}
                                >
                                    <FontAwesomeIcon
                                    className="fs-3 text-light me-2"
                                    icon={faLinkedinIn}
                                    />
                                    @careisgoldspa
                                </button>       
                                </a>
                            </div>
                            <div className="col-12 text-center">
                                <a className="text-decoration-none" href="https://www.youtube.com/@careisgoldspa" target="_blank" rel="noopener noreferrer">
                                <button
                                    className="bg-youtube border-0 shadow mx-auto text-center d-flex justify-content-evenly text-light fw-semibold rounded mt-4 align-items-center"
                                    style={{"height":"3rem","width":"12rem"}}
                                >
                                    <FontAwesomeIcon
                                    className="fs-3 text-light me-2"
                                    icon={faYoutube}
                                    />
                                    @careisgoldspa
                                </button>       
                                </a>
                            </div>
                            <div className="col-12 text-center mt-4">
                                <p className="text-animate-gold fs-4 fw-semibold">La tua opinione è importante</p>
                            </div>
                            <div className="col-12 text-center">
                            <a
                            href="https://it.trustpilot.com/evaluate/careisgold.it"
                            className="text-decoration-none"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                                <button
                                className="bg-white shadow border-1 border-primary mx-auto text-center d-flex justify-content-evenly fw-semibold text-dark rounded mt-md-4 mt-1 align-items-center"
                                style={{"height":"5rem","width":"100%"}}
                                >
                                    Scrivi una Recensione
                                    <img src={trustpilotLogo} alt="logo google" className="ms-2 img-fluid" style={{"width":"7rem"}}/>
                                </button>
                            </a>                            
                            </div>
                            <div className="col-12 text-center">
                            <a
                            href="https://g.page/r/CUYtg0jmErBdEBM/review"
                            className="text-decoration-none"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                                <button
                                className="bg-white shadow border-1 border-primary mx-auto text-center d-flex justify-content-evenly fw-semibold text-dark rounded mt-md-4 mt-2 mb-5 align-items-center"
                                style={{"height":"5rem","width":"100%"}}
                                >
                                    Scrivi una Recensione
                                    <img src={googleLogo} alt="logo google" className="ms-2 img-fluid" style={{"width":"7rem"}}/>
                                </button>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Links